import useJwt from '@src/auth/jwt/useJwt'
import { EncryptStorage } from 'encrypt-storage';
import moment from 'moment';

var date = new Date();

const initialState = {
    token: '',
    treinamento: '',
    carregando:false,
    buscar:false,
    compras: [],

    empresa: '',
    user: [],
    usuario: [],
    empresas: [],
    usuarios: [],
    data: [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)],
    checklist: '',
    usuarioSelecionado: '',
    task_responsible_group: [],
    task_responsible_list: [],
    task_created: [],
    meus_riscos:[],
    treinamentos:[],
    top_riscos:[],
    areas: [],
    funcoes: [],
    setTiposChamados:[],
    pix_motorista:[],
    pix_motorista_pendencia:[],
    reunioes:[],
    area_context:{value:null, label:'Areas'},
    funcao:{value:null, label:'Todas'},
    visitantes: [],
    tipos: [],
    dashboard:[],

    filtro: {
        inicio: moment().startOf('month').format('YYYY-MM-DD'),
        fim: moment().endOf('month').format('YYYY-MM-DD'),
        colaborador:{label:'Selecione', value:null},
        empresa:{label:'Selecione', value:null},
    },




    
};




const config = useJwt.jwtConfig
export default (state = initialState, action = {}) => {

    const encryptStorage = new EncryptStorage('secret-key', {
        encAlgorithm: 'Rabbit',
      });

    
    switch(action.type) {
     
        case 'setUser':
          
            encryptStorage.setItem('userDataCrypt',  JSON.stringify(action.payload.user));
            encryptStorage.setItem('token',  JSON.stringify(action.payload.user.token));

            localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.user.refreshToken))
            return {...state, user: action.payload.user};
        break;
        case 'setUsuario':
              return {...state, usuario: action.payload.usuario};
         break;
        
        case 'setTreinamento':
            return {...state, treinamento: action.payload.treinamento};
        break;
        case 'setEmpresas':
            return {...state, empresas: action.payload.empresas};
        break;
        case 'setEmpresa':
            return {...state, empresa: action.payload.empresa};
        break;
        case 'setUsuarios':
            return {...state, usuarios: action.payload.usuarios};
        break;
        case 'setData':
            return {...state, data: action.payload.data};
        break;
        case 'setFiltro':
            return {...state, filtro: action.payload.filtro};
        break;
        case 'setChecklist':
            return {...state, checklist: action.payload.checklist};
        break;
        case 'SetTaskResponsibleGroup':
            return {...state, task_responsible_group: action.payload.task_responsible_group};
        break;
        case 'SetTaskResponsibleList':
            return {...state, task_responsible_list: action.payload.task_responsible_list};
        break;
        case 'SetToTaskResponsible':
            return {...state, task_created: action.payload.task_created};
        break;
        case 'SetMyRiscos':
            return {...state, meus_riscos: action.payload.meus_riscos};
        break;
        case 'setTreinamentos':
            return {...state, treinamentos: action.payload.treinamentos};
        break;
        case 'setTopRiscos':
            return {...state, top_riscos: action.payload.top_riscos};
        break;
        case 'setAreas':
            return {...state, areas: action.payload.areas};
        break;
        case 'setFuncoes':
            return {...state, funcoes: action.payload.funcoes};
        break;
        case 'setTiposChamados':
            return {...state, tipos_chamados: action.payload.tipos_chamados};
        break;
        case 'setPixMotorista':
            return {...state, pix_motorista: action.payload.pix_motorista};
        break;
        case 'setPixMotoristaPendencia':
            return {...state, pix_motorista_pendencia: action.payload.pix_motorista_pendencia};
        break;
        case 'setReunioes':
            return {...state, reunioes: action.payload.reunioes};
        break;
        case 'saveArea':
            return {...state, area_context: action.payload.area_context};
        break;
        case 'saveFuncao':
            return {...state, funcao: action.payload.funcao};
        break;
        case 'saveVisitantes':
            return {...state, visitantes: action.payload.visitantes};
        break;
        case 'setCarregando':
            return {...state, carregando: action.payload.carregando};
        break;
        case 'setBuscar':
            return {...state, buscar: action.payload.buscar};
        break;
        case 'setTipos':
            return {...state, tipos: action.payload.tipos};
        break;
        case 'setDashboard':
            return {...state, dashboard: action.payload.dashboard};
        break;
        case 'setCompras':
            return {...state, compras: action.payload.compras};
        break;
    }
    return state;
};

